import React, { useState } from "react";

import ContactService from "services/contact.service";
// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

import "./style.css";
export default function Landing() {
  const [message, setMessage] = useState("");
  const [sendMessage, setSendMessage] = useState(false);

  function formSubmit(e) {
    e.preventDefault();
    setSendMessage(true);
    let name = e.target.name.value;
    let email = e.target.email.value;
    let message = e.target.message.value;
    ContactService.postMail(name, email, message).then((res) => {
      setMessage(res.data.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    });
  }
  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
                <div>
                  <h1 className="text-white font-semibold text-4xl">
                    Take Your Earnings to the Next Level with iyosa!
                  </h1>
                  <p className="mt-4 text-lg text-blueGray-200">
                    Integrating iyosa Scenarios into your website is a breeze!
                    Just copy and paste the code into the header area of your
                    website, and you can be up and running in as little as 10
                    seconds!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Fast Integration</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Are you tired of long integrations? Add the one-line iyosa
                      code to your website and start using it right away.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Easy To Use</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Iyosa makes it incredibly simple to activate multiple
                      scenarios with just one click - no more juggling multiple
                      panels!
                    </p>
                  </div>
                </div>
              </div>

              <div className="lg:pt-12 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Increase Sales</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Increase your sales about 40%. It supports conversion
                      rates and ensures that all your campaigns are visible!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-16">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Compatible with all ecommerce solutions
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                  It is appropriate with all ecommerce solutions such as
                  Shopify, Magento, WooCommerce, Prestashop, Ticimax, Tsoft and
                  Ideasoft.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                  No coding experience? No problem! With the code Iyosa
                  provides, you can quickly and easily integrate it into your
                  product to increase your sales. You don't need any extra
                  software developer to support. Get started right away!
                </p>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-lightBlue-500 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      We are ready to help
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                      If you have any queries or would like to know more, please
                      don't hesitate to get in touch - we'd be delighted to work
                      with you!
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-10">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4 mt-6">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4 mt-6">
                <div className="md:pr-12">
                  <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Big Solution! Small Price!
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    iyosa's capabilities are many and its price is very suitable
                    for all e-commerce sites. It works with an average 1:20 ROI.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span
                            className="text-sm font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3"
                            style={{ display: "flex", padding: "8px" }}
                          >
                            <i className="fas fa-tag"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500 text-xl">
                            <span className="pricing-old-price">£899</span> £499
                            / month
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span
                            className="text-sm font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3"
                            style={{ display: "flex", padding: "8px" }}
                          >
                            <i className="fas fa-tag"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500 text-xl">
                            <span className="pricing-old-price">£799</span> £399
                            / months (Paid 6 months)
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span
                            className="text-sm font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3"
                            style={{ display: "flex", padding: "8px" }}
                          >
                            <i className="fas fa-tag"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500 text-xl">
                            <span className="pricing-old-price">£699</span> £299
                            / months (Paid 12 months)
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-12 pb-20">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-4">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">References</h2>
                <p className="text-lg leading-relaxed m-4 text-blueGray-500">
                  companies that choose us
                </p>
              </div>
            </div>
            <div className="flex flex-wrap">
              {/** 
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="beverly rug "
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/beverlyrug-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              */}
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="cook mutfak gereçleri"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/cook-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="fatma atasoy"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/fatmaatasoy-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="fresh scarfs"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/freshscarfs-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              {/**
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="goofie studio"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/goofie-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              */}
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="miniso oyuncak"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/miniso-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              {/**
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="mugo"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/mugo-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              */}
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="shule bags"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/shule-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              {/**
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="welcome baby"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/welcomebaby-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="altuda"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/altuda-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="angel mia"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/angelmia-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="barine"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/barine-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="eazy"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/eazy-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              */}
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="giesto"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/giesto-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              {/**
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="haphug"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/haphug-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="harrem"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/harrem-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="hypetr"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/hypetr-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="markapia"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/markapia-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="minval"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/minval-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="moda çelikler"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/modacelikler-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="moda sima"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/modasima-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="myderi"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/myderi-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="odsports"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/odsports-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="ohmshole"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/ohmshole-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="orenda design"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/orenda-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="peddon"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/peddon-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="rimu"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/rimu-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="roomroomies"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/roomroomies-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="swiss"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/swiss-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              */}
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="talyafoods"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/talyafoods-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="manuka"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/manuka-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="vena"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/vena-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="ozemleyasam"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/ozemleyasam-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              {/**
              <div className="w-6/12 md:w-4/12 lg:w-3/12 lg:mb-6 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="uygun bebe"
                    style={{ filter: "grayscale(1)" }}
                    src={"/img/uygunbebe-300px.png"}
                    className="rounded-full mx-auto max-w-120-px"
                  />
                </div>
              </div>
              */}
              {/**
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/team-2-800x800.jpg").default}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Romina Hadid</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Marketing Specialist
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-google"></i>
                      </button>
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/team-3-800x800.jpg").default}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Alexa Smith</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      UI/UX Designer
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-google"></i>
                      </button>
                      <button
                        className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-twitter"></i>
                      </button>
                      <button
                        className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-instagram"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/team-4-470x470.png").default}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Jenna Kardi</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Founder and CEO
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-dribbble"></i>
                      </button>
                      <button
                        className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-google"></i>
                      </button>
                      <button
                        className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-twitter"></i>
                      </button>
                      <button
                        className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-instagram"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
               */}
            </div>
          </div>
        </section>
        <section className="pb-20 relative block bg-blueGray-800">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64 pt-12">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">Scenarios</h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                  What is Iyosa? Iyosa is helping you grow your sales through
                  the addition of tailored scenarios. Check them out below and
                  get in touch with us via the form to get started.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-heading text-xl"></i>
                </div>
                <h6 className="text-s mt-5 font-semibold text-white">
                  Title Changer
                </h6>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="far fa-clock text-xl"></i>
                </div>
                <h5 className="text-s mt-5 font-semibold text-white">
                  Top Banner & Countdown
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-truck-moving text-xl"></i>
                </div>
                <h5 className="text-s mt-5 font-semibold text-white">
                  Shipping Free Notice
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="far fa-eye text-xl"></i>
                </div>
                <h6 className="text-s mt-5 font-semibold text-white">
                  Social Proof
                </h6>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="far fa-window-restore text-xl"></i>
                </div>
                <h5 className="text-s mt-5 font-semibold text-white">
                  Opening Popup
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="far fa-window-restore text-xl"></i>
                </div>
                <h5 className="text-s mt-5 font-semibold text-white">
                  Exit Popup
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="far fa-circle text-xl"></i>
                </div>
                <h6 className="text-s mt-5 font-semibold text-white">
                  Stories
                </h6>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-bell text-xl"></i>
                </div>
                <h5 className="text-s mt-5 font-semibold text-white">
                  Opportunity of Day
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="far fa-life-ring text-xl"></i>
                </div>
                <h5 className="text-s mt-5 font-semibold text-white">
                  Wheel Of Fortune
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fa fa-search text-xl"></i>
                </div>
                <h6 className="text-s mt-5 font-semibold text-white">
                  Popular Search
                </h6>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fa fa-comments text-xl"></i>
                </div>
                <h5 className="text-s mt-5 font-semibold text-white">
                  Trendyol Comments
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
              <div
                className="w-3/12 lg:w-3/12 px-4 text-center"
                style={{ width: "33.33%" }}
              >
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fa fa-mobile text-xl"></i>
                </div>
                <h5 className="text-s mt-5 font-semibold text-white">
                  Smart App Banner
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400"></p>
              </div>
            </div>
          </div>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
          <div className="container mx-auto px-4 mt-6">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  <form onSubmit={formSubmit}>
                    <div className="flex-auto p-5 lg:p-10">
                      <h4 className="text-2xl font-semibold">
                        Want to work with us?
                      </h4>
                      <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                        Complete this form and we will get back to you in 24
                        hours.
                      </p>
                      <div className="relative w-full mb-3 mt-8">
                        <label
                          className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                          htmlFor="full-name"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Full Name"
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <input
                          required
                          type="email"
                          name="email"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Email"
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                          htmlFor="message"
                        >
                          Message
                        </label>
                        <textarea
                          name="message"
                          required
                          rows="4"
                          cols="80"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Type a message..."
                        />
                      </div>
                      <div className="text-center mt-6">
                        <button
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                          disabled={sendMessage}
                        >
                          Send Message
                        </button>
                      </div>
                      <div className="text-lg">{message}</div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
